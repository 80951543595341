.App {
  font-family: sans-serif;
  text-align: center;
}

.lst {
  font-size: 120px;
}

.image{
  margin: 4px auto 10px;
  display: block;
  width: 220px;
  height: 180px;
}