@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:ital,wght@0,200;1,300&family=Montserrat&display=swap');
.nsk-img {
    width: 100%;
    position: relative;
    text-align: center;
    
}
.inforamtion{
    font-size: 40px;
    width: 100%;
    position: relative;
    text-align: center;
}

.nsk{
    font-family: 'Chivo Mono', monospace;
    font-size: 20px;
    margin: 13px;
    border-radius: 0%;
    text-align: center;
    background-color: antiquewhite;
    padding-left: 5%;
    padding-right: 5%;
    border: solid 3px rgb(255, 225, 186);
    display: block;
}
.inf-4{
    padding: 0%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    text-indent: 40px ;
    padding-top: 10px;
}

.arh-h1{
    padding: 0%;
    padding-top: 20px;
    color: rgba(6, 6, 146);
    font-family:Arial, Helvetica, sans-serif;
    
}
.sbr-h1{
    padding: 2%;
}
.obr-h1{
    padding: 2%;
}
.alle2{
    /* background-color: rgb(255, 216, 142); */
    padding-bottom: 30px;
    padding-top: 30px;
    font-size: 200%;
    font-family:Arial, Helvetica, sans-serif;
    display: inline;
    border-radius: 20px;
    color:rgba(6, 6, 146);
    text-decoration-style: double;
    margin: 20px;
}
.alle3{
    /* background-color: rgb(255, 216, 142); */
    /* padding-bottom: 30px; */
    padding-top: 30px;
    padding-left: 17%;
    font-size: 200%;
    font-family:Arial, Helvetica, sans-serif;
    display: inline;
    border-radius: 20px;
    color:rgba(6, 6, 146);
    text-decoration-style: double;
    margin: 20px;
    float:left;
}
.icons{
    font-size: 300%;
    text-align: left;
}
.h{
    color: rgb(53, 4, 64);
    font-size: 290%;
    /* margin: 5%; */
    padding: 1%;
}
.BiExit{
    display: flex;
    justify-content: flex-end;
    font-size: 350%;
    color:  rgb(18, 0, 154);
}
.BiExit1{
    display: flex;
    justify-content: flex-end;
    font-size: 200%;
    color:  rgba(18, 0, 154, 0.773);
}

.Sviat{
    padding: 5%;
}
.smr {
    padding: 30px;
}
.vozn{
    padding: 30px;
}
.gimn{
    padding: 40px;
}
.foto{
    float:left;
    margin-right: 1em ;
    margin-top: 0px;
}
.block{
    display: table-cell;
    margin-right: 0em ;
    margin-top: 5px;
    width: 100%;
}

/* Changing text color on hover */
.inf-6:hover {
    color: rgb(245, 8, 8);
    font-size: 160%;
    }