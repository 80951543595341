@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;1,300&display=swap');
.App {
  text-align: center;
}

/* @font-face {
  font-family: "AlumniSansCollegiateOneRegular";
  src: local("AlumniSansCollegiateOneRegular"),
  url("./fonts/Alumni_Sans_Collegiate_One/AlumniSansCollegiateOne-Regular.ttf") format("truetype");
  font-weight: normal;

  font-family: "AlumniSansCollegiateOneItalic";
  src: local("AlumniSansCollegiateOneItalic"),
  url("./fonts/Alumni_Sans_Collegiate_One/AlumniSansCollegiateOne-Italic.ttf") format("truetype");
  font-weight: normal;

  font-family: "GreatVibesRegular";
  src: local("GreatVibesRegular"),
  url("./fonts/Great_Vibes/GreatVibes-Regular.ttf'") format("truetype");
  font-weight: normal;
  } */


.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px);
}
