.MainClassInformContact {
    
    margin-top: 5%;
    text-align: center;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(25px);
    font-size: 200%;
    text-indent: 0px ;
    padding-left: 17%;
    padding-right: 17%;
   color:  rgb(18, 0, 154);
}

.MainClassInformContact1 {
    
    margin-top: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(25px);
    font-size: 200%;
    text-indent: 0px ;
    padding-left: 17%;
    padding-right: 17%;
   color:  rgb(18, 0, 154);
}