.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.all-pages-container {
  height: 100%;
  display: flex;
  transition: translate;
  transition-property: transform;
  /* transition-duration: 300ms; */
  transition-timing-function: ease-in-out;
}

.arrow {
  cursor: pointer;
  font-size: 220%;
}
