@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@200&display=swap');
.nsk-img {
    width: 100%;
    position: relative;
    text-align: center;
    
}
.main{
    text-align: justify;
    background-color: antiquewhite;
    padding-top: 1%;
    padding-left: 6%;
    padding-right: 6%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif;
    text-indent: 40px ;
}
.head{
    padding: 0%;
    justify-content: center;
    font-size: 300%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    padding-top: 5%;
    padding-bottom: 3%;
    color:rgb(38, 38, 174) ;
    
}

.head9{
    padding: 0%;
    justify-content: center;
    font-size: 300%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    padding-top: 5%;
    padding-bottom: 3%;
    color:rgb(38, 38, 174);
    
}

.churches-title{
    padding: 0%;
    justify-content: center;
    font-size: 160%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    padding-top: 2%;
    color:rgb(38, 38, 174) ;
    
}
.table-w{
    width: 100%;
    
}

.inf-4{
    padding: 0%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    text-indent: 40px ;
    padding-top: 0px;
}
.inf-5{
    padding: 0%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    text-indent: 40px ;
    padding-top: 0px;
    margin-top: 0px;
    line-height: 1.2;
}

.inf-5_2{
    padding: 0%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    text-indent: 40px ;
    padding-top: 0px;
    margin-top: 10px;
    line-height: 1.2;
}

.inf-5_podpis{
    padding: 0%;
    font-size: 110%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: right;
    text-indent: 40px ;
    padding-top: 0px;
    margin-top: 10px;
    line-height: 1.2;
    font-style: italic;
}
.inf-6_podpis{
    padding: 0%;
    font-size: 110%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    text-indent: 40px ;
    padding-top: 0px;
    margin-top: 10px;
    line-height: 1.2;
    font-style: italic;
}

.inf-6{
    padding: 0%;
    font-size: 150%;
    font-family: "Great Vibes";
    -webkit-text-stroke: 0.6px;
    -webkit-text-stroke-color: rgba(48, 0, 93, 0.864); 
    text-align: justify;
    text-indent: 40px ;
    padding-top: 0px;
}

.inf-8{
    padding: 0%;
    font-size: 180%;
    font-family: "Great Vibes";
    -webkit-text-stroke: 0.6px;
    -webkit-text-stroke-color: rgba(48, 0, 93, 0.864); 
    text-align: center;
    text-indent: 40px ;
    padding-top: 0px;
}

.inf-7{
    padding: 0%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
    text-indent: 40px ;
    padding-top: 0px;
    line-height: 1.4;
}


.inf-podpis{
    padding: 0%;
    font-size: 90%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    text-indent: 0px ;
    padding-top: 0px;
    font-weight: 500;
    font-style: italic;
}

.podpis{
    padding: 0%;
    font-size: 90%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    text-indent: 0px ;
    padding-top: 0px;
    font-weight: 500;
    font-style: italic;
}

.flag{
    justify-content: center;
    text-align: center;
    float:left;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
}
.church-2-title{
    padding: 0%;
    font-size: 140%;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    text-indent: 0px ;
    padding-top: 0.5%;
    font-weight:bold;
    color:rgb(185, 6, 6) ;
 }
 
 .church-1-title{
    padding: 0%;
    font-size: 140%;
    font-family:Arial, Helvetica, sans-serif;
    text-align: center;
    text-indent: 0px ;
    padding-top: 0 px;
    padding-bottom: 10px;
    font-weight:bold;
    color:rgb(185, 6, 6) ;
 }
 .church-1-image{
    justify-content: center;
    text-align: center;
    float:left;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
}

.church-1r1-image{
    justify-content: center;
    text-align: center;
    float:right;
    margin-left: 1em ;
    margin-top: 0em;
    text-indent: 0px ;
}

.church-1r-image{
    justify-content: center;
    text-align: center;
    float:right;
    margin-left: 1em ;
    margin-top: 1em;
    text-indent: 0px ;
}

.church-1l-image{
    justify-content: center;
    text-align: center;
    float:left;
    margin-right: 1em ;
    margin-top: 0.5em;
    margin-bottom: 0em;
    text-indent: 0px ;
}

.center-image{
    justify-content: center;
    text-align: center;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
}

.church-image-double{
    justify-content: center;
    text-align: center;
    float:left;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
}
.attraction-1-image{
    justify-content: center;
    text-align: center;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
    
}

.t1{
    justify-content: center;
    text-align: center;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
    width: 100%;
    
}


.image-podpis{
    justify-content: center;
    text-align: center;
    margin-right: 1em ;
    margin-top: 0em;
    text-indent: 0px;
}
.attractions{
    justify-content: center;
    text-align: center;
    margin-right: 1em ;
    margin-top: 0px;
    text-indent: 0px ;
    color: rgb(38, 38, 174);
}
.stolbik{
    display: table-cell;
    vertical-align: middle;
}
.stroka{
    display: table-cell;
    vertical-align: middle;
}
.attraction-1-text {
    color: rgb(18, 0, 154);
    text-indent: 0px;
    padding-top: 5px;
    font-weight: 600;
    
}

.table{
    text-align: center;
    vertical-align: top;
}


