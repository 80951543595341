.app__main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .app__main-container > *:not(:last-child) {
    margin-bottom: 50px;
  }
  
  /* .item{
    padding-top: 5%;
  } */
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    height: 100%;
    width: 100%;
  }
  .name{
   font-size: 0%;
    /* font-size: 40px; */
    /* float: inline-start;
    position: inherit;
    padding-left: 300%; */
  }