.Main {
    /* background-color: aliceblue; */
    background-color: rgb(193, 216, 239);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.BsJustify {
    font-size: 230%;
    color: black;
}

.Header {
    width: 75%;
    display: flex;
    justify-content: center;
    border-radius: 1%;
    background: rgba(255, 255, 255, 0);
    padding: 0%;
    margin-left: 12%;
    margin-top: 0%;
    font-size: 500%;
    font-family: "Alumni Sans Collegiate One", sans-serif;
    font-weight: 400;
    /* color: rgb(91, 32, 187); */
    /* color: rgb(218, 54, 4); */
    color: rgb(167, 11, 11);
    text-align: center;
    line-height: 0.9;
    -webkit-text-stroke: 0.4px;
    -webkit-text-stroke-color: rgb(255, 255, 255);


}

.off-top {
    display: flex;
    margin-top: 0%;
    justify-content: center;
    /* font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    font-family: "Great Vibes";
    font-size: 300%;
    font-weight: 600;
    /* color: rgba(48, 0, 93, 0.864);  */
    color: rgb(18, 0, 154);
    line-height: 1.1;
    -webkit-text-stroke: 0.px;
    -webkit-text-stroke-color: aqua;

    /* align-items: center;
    font-siz */
}

.search {
    display: flex;
    justify-content: center;
    padding: 3%;
    font-size: 250%;
}

input {
    font-size: 60%;
    background-color: rgb(255, 255, 255);
    width: 50%;
}

input[type=text]:focus {
    background-color: lightblue;
}

.info {
    margin-top: 5%;
    width: 100%;
    display: grid;
    justify-content: space-around;
}

.inf-1 {
    padding: 10%;
    border-radius: 0%;
    border: 1px solid black;
    background-color: rgb(118, 175, 196);
    font-size: 200%;
    text-align: center;

}

.sem {
    text-decoration: none;
    color: black;

}

.inf-2 {
    padding: 7%;
    border-radius: 45%;
    border: 3px solid rgba(180, 229, 247, 0.5);
    background-color: rgba(180, 229, 247, 0.5);
    font-size: 260%;
    /* color: rgb(91, 32, 187); */
    color:  rgb(18, 0, 154);
    text-align: center;
    line-height: 1;
    font-weight: 500;
    /* font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;  */
    font-family: "Great Vibes";
    -webkit-text-stroke: 0.8px;
    -webkit-text-stroke-color: rgba(48, 0, 93, 0.864);

}

.inf-3 {
    padding: 20%;
    border-radius: 25%;
    background-color: rgb(118, 175, 196);
    font-size: 210%;
    text-align: center;

}

.info_about_project {
    padding: 20%;
    display: flex;
    justify-content: center;
    font-size: 40px;
}

.BsJustify {
    font-size: 290%;
    color: black;
}

.info {
    margin-bottom: 100px;

}

.k {
    border-radius: 30px;
}

.alle {
    font-size: 40px;
    margin-top: 400px;
    display: flex;
    justify-content: center;
    color: rgb(246, 246, 246);
}

.lk {
    vertical-align: middle;
    display: inline-block;
    border-radius: 20px;
    background-color: rgb(164, 165, 166);
    padding-bottom: 80px;
    display: flex;
    justify-content: center;
    padding-top: 7%;
    color: aliceblue;
}

.a-tg {
    color: aliceblue;
}

.ghoul {
    padding: 230px;
    display: flex;
    justify-content: center;
}

header {
    opacity: 0;
    animation: ani 2.5s forwards
}

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

html {
    background-image: url(bg_3.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

@keyframes ani {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.middle {
    vertical-align: middle;
}

.sv-title {
    padding: 0%;
    font-size: 130%;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    text-indent: 0px;
    padding-top: 0%;
    font-weight: bold;
    color: rgb(185, 6, 6);
}

.inf-link {
    /* padding: 10%; */
    /* border-radius: 45% ; */
    /* border: 3px solid rgba(180, 229, 247, 0.5); */
    /* background-color: rgba(180, 229, 247, 0.5); */
    font-size: 10%;
    color: rgb(91, 32, 187);
    text-align: center;
    /* font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;  */
    /* font-family: fantasy; */

}

/* Changing text color on hover */
.inf-2:hover {
    color: rgb(138, 9, 9);
    border: 3px solid rgb(138, 9, 9);
    background-color: rgba(224, 235, 240, 0.5);

}

.ClassContact{
    margin-bottom: -100%;
    padding: 1%;
    background-color: rgba(152, 228, 249, 0.531);
    text-align: center;
    border-radius: 10%;
}

.AConcact{
    font-size: 150%;
    color: rgba(18, 0, 154, 0.845);
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    
    
    
}

.NsuMetropolisTag {
    font-size: 130%;
    color: rgba(18, 0, 154, 0.845);
}

.ClassTop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Занять всю ширину */
    font-weight: 700;
    padding-top: 1%;
    text-align: center;
    border-radius: 0%;
}