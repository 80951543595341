/* 
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i')
/*font-family: 'Roboto', sans-serif;*/
/* html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td
{
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 80%;
    font: inherit;
    vertical-align: baseline;
} */
/*HTML5 display-role reset for older browsers */
/* article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block; margin: 8px;}
body {line-height: 1;} */
/* ol, ul {list-style: none;} */
/* blockquote, q {quotes: none;}
blockquote:before, blockquote:after, q:before, q:after
{
    content: '';
    content: none;
} */
/* table
{
    border-collapse: collapse;
    border-spacing: 0;
} */

/*/////////////////////////////////////////*/

.map
{
    position: relative;
    text-align: center;
}

.ll{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.path
{
    opacity: 0.0;
    fill: white;
    cursor: pointer;
    transition: 0.3s;
}
.path:hover
{
    opacity: 0.4;
    transition: 0.3s;

}
.map{
    background-color: rgb(235, 241, 183);
} 